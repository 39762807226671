import React from "react";

function PrivacyPolicy() {
  return (
    <main className="container special-page">
      <div className="special-block">
        <h2 className="special-page-title">
          Privacybeleid van hoteldeeikenboom.com
        </h2>
        <p className="special-block-text">
          Bij hoteldeeikenboom.com, dat toegankelijk is via
          hoteldeeikenboom.com, staat de privacy van onze bezoekers hoog in het
          vaandel. Dit privacybeleidsdocument beschrijft welke soorten
          informatie worden verzameld en geregistreerd door hoteldeeikenboom.com
          en hoe we deze informatie gebruiken.
        </p>
        <p className="special-block-text">
          Als u nog vragen heeft of meer details wilt over ons privacybeleid,
          aarzel dan niet om contact met ons op te nemen.
        </p>
        <p className="special-block-text">
          Dit privacybeleid is uitsluitend van toepassing op onze online
          activiteiten en geldt voor bezoekers van onze website met betrekking
          tot de informatie die wordt gedeeld en/of verzameld op
          hoteldeeikenboom.com. Dit beleid is niet van toepassing op informatie
          die offline of via andere kanalen dan deze website wordt verzameld.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Toestemming</h3>
        <p className="special-block-text">
          Door gebruik te maken van onze website, stemt u in met ons
          privacybeleid en de voorwaarden ervan.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Gegevens die we verzamelen</h3>
        <p className="special-block-text">
          De persoonlijke gegevens die we van u vragen, en de reden waarom we
          deze vragen, zullen duidelijk worden gemaakt wanneer we om uw
          persoonsgegevens vragen.
        </p>
        <p className="special-block-text">
          Als u rechtstreeks contact met ons opneemt, kunnen we aanvullende
          informatie van u ontvangen, zoals uw naam, e-mailadres,
          telefoonnummer, de inhoud van uw bericht en/of eventuele bijlagen die
          u ons stuurt, evenals andere informatie die u verstrekt.
        </p>
        <p className="special-block-text">
          Als u rechtstreeks contact met ons opneemt, kunnen we aanvullende
          informatie van u ontvangen, zoals uw naam, e-mailadres,
          telefoonnummer, de inhoud van uw bericht en/of eventuele bijlagen die
          u ons stuurt, evenals andere informatie die u verstrekt.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Hoe we uw gegevens gebruiken</h3>
        <p className="special-block-text">
          De informatie die we verzamelen wordt op verschillende manieren
          gebruikt waaronder:
        </p>
        <ul className="special-block-list">
          <li>Het leveren, beheren en onderhouden van onze website</li>
          <li>Het verbeteren, personaliseren en uitbreiden van onze website</li>
          <li>Inzicht krijgen in en analyseren hoe u onze website gebruikt</li>
          <li>
            Het ontwikkelen van nieuwe producten, diensten, functies en
            mogelijkheden
          </li>
          <li>
            Direct of via onze partners met u communiceren, inclusief voor
            klantenservice, updates en andere informatie over de website, en
            voor marketingdoeleinden
          </li>
          <li>Het verzenden van e-mails naar u</li>
          <li>Het opsporen en voorkomen van fraude</li>
        </ul>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Logbestanden</h3>
        <p className="special-block-text">
          hoteldeeikenboom.com hanteert een standaardprocedure voor het gebruik
          van logbestanden. Deze bestanden registreren bezoekers wanneer zij
          websites bezoeken. Dit is gebruikelijk bij alle hostingbedrijven en
          maakt deel uit van de analyse van hostingdiensten. De gegevens die via
          logbestanden worden verzameld, omvatten onder andere IP-adressen,
          browsertype, internetserviceprovider (ISP), datum en tijdstempels,
          verwijzende/exitpagina's, en mogelijk het aantal klikken. Deze
          informatie bevat geen persoonlijke gegevens. Het wordt gebruikt om
          trends te analyseren, de website te beheren, gebruikersbewegingen op
          de site te volgen en demografische informatie te verzamelen.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">
          Privacybeleid voor advertentiepartners
        </h3>
        <p className="special-block-text">
          In deze sectie kunt u het privacybeleid van elk van onze
          reclamepartners vinden die betrokken zijn bij hoteldeeikenboom.com.
        </p>
        <p className="special-block-text">
          Advertentieservers of advertentienetwerken van derden gebruiken
          technologieën zoals cookies, JavaScript of webbakens in de
          advertenties en links die op hoteldeeikenboom.com verschijnen. Deze
          technologieën worden rechtstreeks naar uw browser verzonden en zij
          ontvangen automatisch uw IP-adres. Deze technologieën worden gebruikt
          om de effectiviteit van hun advertentiecampagnes te meten en om de
          advertenties die u op websites ziet aan te passen.
        </p>
        <p className="special-block-text">
          Let op dat hoteldeeikenboom.com geen toegang heeft tot, noch controle
          heeft over de cookies die door derde partijen worden gebruikt.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Privacybeleid voor derden</h3>
        <p className="special-block-text">
          Het privacybeleid van hoteldeeikenboom.com is niet van toepassing op
          andere adverteerders of websites. We raden u aan om het respectieve
          privacybeleid van deze derden advertentienetwerken te bekijken voor
          meer informatie. Dit kan hun praktijken en instructies bevatten over
          hoe u zich kunt afmelden voor bepaalde opties.
        </p>
        <p className="special-block-text">
          U kunt cookies uitschakelen via de instellingen van uw browser. Voor
          gedetailleerde informatie over het beheren van cookies in specifieke
          browsers, kunt u de helpsectie van uw browser of de websites van de
          desbetreffende browsers raadplegen.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">
          CCPA-privacyrechten (verkoop mijn persoonlijke informatie niet)
        </h3>
        <p className="special-block-text">
          Volgens de CCPA hebben consumenten in Californië onder andere het
          recht om:
        </p>
        <p className="special-block-text">
          Te eisen dat een bedrijf dat persoonsgegevens van consumenten
          verzamelt, de categorieën en specifieke gegevens onthult die het over
          hen heeft verzameld.
        </p>
        <p className="special-block-text">
          Het bedrijf te verzoeken om alle persoonlijke informatie van
          consumenten die het heeft verzameld te wissen.
        </p>
        <p className="special-block-text">
          Een bedrijf dat persoonsgegevens van consumenten verkoopt, te vragen
          om uw persoonlijke informatie niet te verkopen.
        </p>
        <p className="special-block-text">
          Als u een verzoek indient, hebben wij een maand de tijd om hierop te
          reageren. Neem contact met ons op als u gebruik wilt maken van een van
          deze rechten.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">AVG-gegevensbeschermingsrechten</h3>
        <p className="special-block-text">
          We willen ervoor zorgen dat u volledig op de hoogte bent van al uw
          rechten met betrekking tot gegevensbescherming. Elke gebruiker heeft
          recht op het volgende:
        </p>
        <p className="special-block-text">
          Recht op toegang - U heeft het recht om kopieën van uw persoonlijke
          gegevens aan te vragen. We kunnen u een kleine vergoeding in rekening
          brengen voor deze service.
        </p>
        <p className="special-block-text">
          Recht op rectificatie - U heeft het recht om ons te verzoeken alle
          informatie te corrigeren waarvan u denkt dat deze onjuist of
          onnauwkeurig is. U heeft ook het recht om ons te vragen de door u
          gewenste informatie aan te vullen als deze onvolledig is.
        </p>
        <p className="special-block-text">
          Recht op verwijdering - In sommige gevallen heeft u het recht om te
          verzoeken dat wij uw persoonlijke gegevens verwijderen.
        </p>
        <p className="special-block-text">
          Recht op beperking van de verwerking - U heeft het recht om ons te
          verzoeken de verwerking van uw persoonlijke gegevens onder bepaalde
          voorwaarden te beperken.
        </p>
        <p className="special-block-text">
          Recht om bezwaar te maken tegen verwerking - U heeft het recht om
          bezwaar te maken tegen onze verwerking van uw persoonlijke gegevens
          onder bepaalde voorwaarden.
        </p>
        <p className="special-block-text">
          Recht op gegevensoverdraagbaarheid - U heeft het recht om de
          overdracht van de gegevens die we hebben verzameld aan te vragen aan
          een andere organisatie of rechtstreeks aan u onder bepaalde
          voorwaarden.
        </p>
        <p className="special-block-text">
          Als u een verzoek indient, heeft het bedrijf een maand de tijd om te
          reageren. Neem contact met ons op als u gebruik wilt maken van een van
          deze rechten.
        </p>
      </div>
    </main>
  );
}

export default PrivacyPolicy;
