import React from "react";

function CookiePolicy() {
  return (
    <main className="container special-page">
      <div className="special-block">
        <h2 className="special-page-title">
          Cookiebeleid van hoteldeeikenboom.com
        </h2>
        <p className="special-block-text">
          Dit is het cookiebeleid voor hoteldeeikenboom.com, beschikbaar op
          hoteldeeikenboom.com.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Wat zijn cookies?</h3>
        <p className="special-block-text">
          Zoals gebruikelijk op de meeste professionele websites, maakt deze
          website gebruik van cookies. Dit zijn kleine bestanden die op uw
          computer worden gedownload om uw gebruikerservaring te verbeteren. Op
          deze pagina vindt u informatie over welke gegevens worden verzameld,
          hoe we deze gebruiken en waarom we soms deze cookies moeten opslaan.
          We zullen ook uitleggen hoe u kunt voorkomen dat deze cookies worden
          opgeslagen, maar dit kan bepaalde functies van de website beïnvloeden
          of zelfs uitschakelen.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Hoe we cookies gebruiken</h3>
        <p className="special-block-text">
          We gebruiken cookies om verschillende redenen, die hieronder zijn
          uiteengezet. Helaas is het in de industrie vaak niet gebruikelijk om
          standaardopties aan te bieden voor het uitschakelen van cookies zonder
          dat dit de functionaliteit van de website beïnvloedt. Het is aan te
          raden om cookies ingeschakeld te laten als u niet zeker weet of u ze
          nodig heeft, omdat ze belangrijk kunnen zijn voor de service die u
          gebruikt.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Cookies uitschakelen</h3>
        <p className="special-block-text">
          U kunt het plaatsen van cookies voorkomen door uw browserinstellingen
          aan te passen (raadpleeg de helpsectie van uw browser voor
          instructies). Houd er rekening mee dat het uitschakelen van cookies de
          functionaliteit van deze en veel andere websites die u bezoekt, kan
          beïnvloeden. Het uitschakelen van cookies schakelt meestal ook
          bepaalde functies op deze website uit. Daarom is het meestal niet aan
          te raden om cookies uit te schakelen.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">
          Informatiecookies die we plaatsen
        </h3>
        <p className="special-block-text">Cookies voor site-instellingen</p>
        <p className="special-block-text">
          Om ervoor te zorgen dat u een prettige ervaring op onze site heeft,
          bieden wij functionaliteit om uw instellingen te onthouden voor hoe
          deze website werkt wanneer u deze gebruikt. Om uw voorkeuren te
          bewaren, moeten we cookies plaatsen zodat deze informatie kan worden
          opgehaald telkens wanneer u interactie heeft met de website.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Cookies van derden</h3>
        <p className="special-block-text">
          In bepaalde specifieke situaties maken we ook gebruik van cookies van
          betrouwbare externe partijen. In de volgende sectie vindt u details
          over de derde partij cookies die u op deze website kunt tegenkomen.
        </p>
        <p className="special-block-text">
          Deze website maakt gebruik van Google Analytics, een van de meest
          populaire en betrouwbare analytische oplossingen op het internet. Dit
          helpt ons te begrijpen hoe u de site gebruikt en hoe we uw ervaring
          kunnen verbeteren. Deze cookies kunnen informatie verzamelen, zoals de
          tijd die u op de website doorbrengt en welke pagina's u bezoekt, zodat
          we kunnen blijven zorgen voor relevante en interessante inhoud.
        </p>
        <p className="special-block-text">
          Voor meer informatie over de cookies van Google Analytics, kunt u de
          officiële Google Analytics-website bezoeken.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Meer informatie</h3>
        <p className="special-block-text">
          We hopen dat dit de zaken voor u heeft verduidelijkt en, zoals eerder
          vermeld, als u niet zeker weet of u cookies nodig heeft, is het
          meestal veiliger om ze ingeschakeld te laten. Dit zorgt ervoor dat u
          probleemloos kunt interageren met alle functies van onze website.
        </p>
      </div>
    </main>
  );
}

export default CookiePolicy;
