import React from "react";

function TermsAndConditions() {
  return (
    <main className="container special-page">
      <div className="special-block">
        <h2 className="special-page-title">Voorwaarden</h2>
        <p className="special-block-text">Welkom bij hoteldeeikenboom.com!</p>
        <p className="special-block-text">
          Deze algemene voorwaarden beschrijven de regels en voorschriften voor
          het gebruik van de website op hoteldeeikenboom.com.
        </p>
        <p className="special-block-text">
          Door deze website te gebruiken, gaat u akkoord met deze voorwaarden.
          Indien u niet akkoord gaat met een deel van deze voorwaarden, dient u
          af te zien van het verder gebruiken van hoteldeeikenboom.com.
        </p>
        <p className="special-block-text">
          In deze voorwaarden verwijzen de termen "Klant", "U" en "Uw" naar u,
          de gebruiker van deze website die zich conformeert aan deze
          voorwaarden. "Bedrijf", "Wij", "Ons" en "Onze" verwijzen naar
          hoteldeeikenboom.com. Het gebruik van deze of andere vergelijkbare
          termen, in enkelvoud, meervoud, hoofdletters en/of hij/zij, verwijst
          naar dezelfde betekenis en kan onderling uitwisselbaar worden
          gebruikt.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Cookies</h3>
        <p className="special-block-text">
          Wij maken gebruik van cookies. Door gebruik te maken van
          hoteldeeikenboom.com stemt u in met het gebruik van cookies zoals
          beschreven in ons privacybeleid.
        </p>
        <p className="special-block-text">
          De meeste moderne interactieve websites gebruiken cookies om
          gebruikersgegevens te verzamelen en de gebruikerservaring te
          verbeteren. Cookies worden op onze website gebruikt om functionaliteit
          mogelijk te maken in specifieke delen van de site en om het navigeren
          voor bezoekers gemakkelijker te maken. Daarnaast kunnen enkele van
          onze partners ook gebruik maken van cookies.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Licentie</h3>
        <p className="special-block-text">
          Tenzij anders aangegeven, zijn hoteldeeikenboom.com en/of haar
          licentiehouders de eigenaar van de intellectuele eigendomsrechten voor
          al het materiaal dat op hoteldeeikenboom.com beschikbaar is. Alle
          rechten zijn voorbehouden. U mag materiaal van hoteldeeikenboom.com
          alleen gebruiken voor persoonlijk gebruik onder de beperkingen zoals
          uiteengezet in deze voorwaarden.
        </p>
        <p className="special-block-text">Het is u niet toegestaan om:</p>
        <ul className="special-block-list">
          <li>Materiaal van hoteldeeikenboom.com te herpubliceren;</li>
          <li>
            Materiaal van hoteldeeikenboom.com te verkopen, verhuren of in
            sublicentie te geven;
          </li>
          <li>
            Materiaal van hoteldeeikenboom.com te reproduceren, dupliceren of
            kopiëren;
          </li>
          <li>Inhoud van hoteldeeikenboom.com te herdistribueren.</li>
        </ul>
        <p className="special-block-text">
          Bepaalde delen van deze website bieden gebruikers de mogelijkheid om
          meningen en informatie te delen. hoteldeeikenboom.com controleert,
          bewerkt, publiceert of beoordeelt deze opmerkingen niet voorafgaand
          aan hun verschijning op de site. Opmerkingen weerspiegelen uitsluitend
          de mening van de persoon die deze plaatst en niet van
          hoteldeeikenboom.com, haar partners of geaffilieerde entiteiten. Voor
          zover toegestaan door de wet, is hoteldeeikenboom.com niet
          verantwoordelijk voor de opmerkingen of eventuele schade, kosten of
          uitgaven die daaruit voortvloeien.
        </p>
        <p className="special-block-text">
          hoteldeeikenboom.com behoudt zich het recht voor om alle opmerkingen
          te controleren en om opmerkingen te verwijderen die als ongepast of in
          strijd met deze voorwaarden worden beschouwd.
        </p>
        <p className="special-block-text">U garandeert en verklaart dat:</p>
        <ul className="special-block-list">
          <li>
            U het recht hebt om de opmerkingen op onze website te plaatsen en
            beschikt over de nodige licenties en toestemmingen hiervoor;
          </li>
          <li>
            De opmerkingen geen inbreuk maken op intellectuele eigendomsrechten
            van derden, waaronder maar niet beperkt tot auteursrechten, patenten
            of handelsmerken;
          </li>
          <li>
            De opmerkingen geen lasterlijk, aanstootgevend of illegaal materiaal
            bevatten;
          </li>
          <li>
            De opmerkingen niet worden gebruikt voor commerciële promotie of
            illegale activiteiten.
          </li>
        </ul>
        <p className="special-block-text">
          Door opmerkingen te plaatsen, verleent u hoteldeeikenboom.com een
          niet-exclusieve licentie om deze te gebruiken, reproduceren, te
          bewerken en anderen hetzelfde recht te geven in alle vormen en media.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Hyperlinks naar onze inhoud</h3>
        <p className="special-block-text">
          De volgende organisaties mogen zonder voorafgaande schriftelijke
          toestemming naar onze website linken:
        </p>
        <ul className="special-block-list">
          <li>Overheidsinstellingen;</li>
          <li>Zoekmachines;</li>
          <li>Persorganisaties;</li>
          <li>Distributeurs van online directories;</li>
          <li>
            Geaccrediteerde bedrijven, behalve non-profitorganisaties en
            liefdadigheidsinstellingen die mogelijk geen links mogen plaatsen.
          </li>
        </ul>
        <p className="special-block-text">
          Deze organisaties mogen naar onze website linken onder de voorwaarde
          dat de link: (a) niet misleidend is; (b) geen onjuiste goedkeuring
          suggereert; en (c) past binnen de context van de koppelende partij.
        </p>
        <p className="special-block-text">
          We kunnen ook andere verzoeken van de volgende soorten organisaties
          overwegen en goedkeuren:
        </p>
        <ul className="special-block-list">
          <li>algemeen bekende informatiebronnen;</li>
          <li>dot.com-communitysites;</li>
          <li>verenigingen die goede doelen vertegenwoordigen;</li>
          <li>online directories;</li>
          <li>internetportalen;</li>
          <li>accountants, advocaten en consultants; en</li>
          <li>onderwijsinstellingen en brancheorganisaties.</li>
        </ul>
        <p className="special-block-text">
          We zullen verzoeken om een ​​link van deze organisaties naar onze site
          te beoordelen en goed te keuren als we vaststellen dat: (a) de link
          geen negatieve invloed heeft op ons of onze geaccrediteerde partners;
          (b) de organisatie geen ongunstige reputatie heeft bij ons; (c) het
          voordeel voor ons door de zichtbaarheid van de hyperlink groter is dan
          het mogelijke nadeel van het ontbreken ervan op hoteldeeikenboom.com;
          en (d) de link relevant is binnen de algemene informatiecontext van de
          bron.
        </p>
        <p className="special-block-text">
          Deze organisaties mogen naar onze website linken onder de volgende
          voorwaarden: (a) de link mag op geen enkele manier misleidend zijn;
          (b) de link mag geen sponsoring, goedkeuring of associatie suggereren
          met de producten of diensten van de linkende partij; en (c) de link
          moet passend zijn binnen de context van de website van de linkende
          partij.
        </p>
        <p className="special-block-text">
          Als u een organisatie bent die valt onder de genoemde categorieën in
          paragraaf 2 hierboven en u wilt een link naar onze website plaatsen,
          verzoeken wij u ons hiervan op de hoogte te stellen via een e-mail
          naar hoteldeeikenboom.com. Vermeld uw naam, de naam van uw
          organisatie, uw contactgegevens, de URL vanwaar u wilt linken, en een
          lijst van alle URL's van onze site waar u naar wilt linken. Houd
          rekening met een reactietijd van 2-3 weken.
        </p>
        <p className="special-block-text">
          Goedgekeurde organisaties mogen als volgt een link naar onze website
          plaatsen:
        </p>
        <ul className="special-block-list">
          <li>Door gebruik te maken van onze bedrijfsnaam; of</li>
          <li>
            Door gebruik te maken van de Uniform Resource Locator (URL) die naar
            onze website verwijst; of
          </li>
          <li>
            Door een andere beschrijving van onze website te gebruiken, zolang
            deze zinvol is in de context van de link en het format van de
            linkende site.
          </li>
        </ul>
        <p className="special-block-text">
          Het gebruik van het hoteldeeikenboom.com-logo of ander artwork voor
          het plaatsen van een link is niet toegestaan zonder een formele
          licentieovereenkomst.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">iFrame</h3>
        <p className="special-block-text">
          Zonder voorafgaande toestemming en schriftelijke goedkeuring is het
          niet toegestaan om frames te creëren rondom onze websites die op
          enigerlei wijze de visuele presentatie of het uiterlijk van onze
          website wijzigen.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">
          Verantwoordelijkheid voor inhoud
        </h3>
        <p className="special-block-text">
          Wij zijn niet verantwoordelijk voor de inhoud die op uw website wordt
          weergegeven. U stemt ermee in ons te verdedigen en te vrijwaren tegen
          claims die voortvloeien uit inhoud die op uw website verschijnt. Geen
          enkele link mag verschijnen op een website die kan worden beschouwd
          als lasterlijk, obsceen, crimineel of in strijd met de rechten van
          derden.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Rechtsvoorbehoud</h3>
        <p className="special-block-text">
          Wij behouden ons het recht voor om u te verzoeken alle links naar onze
          website te verwijderen. U stemt ermee in om op ons verzoek
          onmiddellijk links naar onze website te verwijderen. We behouden ook
          het recht om deze algemene voorwaarden en het linkbeleid op elk moment
          te wijzigen. Door gebruik te blijven maken van links naar onze
          website, stemt u ermee in gebonden te zijn aan deze voorwaarden.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">
          Verwijdering van links van onze website
        </h3>
        <p className="special-block-text">
          Als u een link op onze website om welke reden dan ook aanstootgevend
          vindt, neem dan contact met ons op en laat het ons weten. We zullen
          verzoeken om links te verwijderen in overweging nemen, maar we zijn
          niet verplicht om dit onmiddellijk te doen.
        </p>
        <p className="special-block-text">
          Wij geven geen garantie dat de informatie op deze website correct of
          volledig is, noch garanderen we dat de website beschikbaar blijft of
          dat de inhoud op de site up-to-date is.
        </p>
      </div>
      <div className="special-block">
        <h3 className="special-block-title">Disclaimer</h3>
        <p className="special-block-text">
          Voor zover toegestaan door de wet, sluiten we alle verklaringen,
          garanties en voorwaarden uit met betrekking tot onze website en het
          gebruik ervan. Niets in deze disclaimer zal:
        </p>
        <ul className="special-block-list">
          <li>
            onze of uw aansprakelijkheid voor overlijden of persoonlijk letsel
            beperken of uitsluiten;
          </li>
          <li>
            onze of uw aansprakelijkheid voor fraude of frauduleuze misleiding
            beperken of uitsluiten;
          </li>
          <li>
            onze of uw aansprakelijkheid beperken op een manier die niet is
            toegestaan onder de toepasselijke wetgeving;
          </li>
          <li>
            aansprakelijkheden uitsluiten die niet kunnen worden uitgesloten
            onder de toepasselijke wetgeving.
          </li>
        </ul>
        <p className="special-block-text">
          Beperkingen en uitsluitingen van aansprakelijkheid zoals beschreven in
          deze sectie en elders in deze disclaimer gelden voor alle
          verplichtingen die voortvloeien uit de disclaimer, inclusief
          aansprakelijkheid uit contractbreuk, schadevergoeding en schending van
          wettelijke verplichtingen.
        </p>
        <p className="special-block-text">
          Zolang de website en de informatie daarop gratis beschikbaar zijn,
          zijn wij niet aansprakelijk voor enig verlies of schade van welke aard
          dan ook.
        </p>
      </div>
    </main>
  );
}

export default TermsAndConditions;
